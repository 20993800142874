<script lang="ts">
  import { override } from '$lib/classnames'

  let className = ''
  export let as = 'span'
  export { className as class }
</script>

<svelte:element
  this={as}
  class={override(
    'text-xs 2xl:text-sm font-medium uppercase tracking-wide text-gray-600 leading-8',
    className,
  )}
>
  <slot />
</svelte:element>
